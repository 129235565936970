import * as React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { WpGql_Page } from '@/graphql-types';
import { ContentContainer } from '../Components/Common/ContentContainer';
import overlay from '@/src/images/defaultOverlay-wide.png';


// Components
import { BrandedTitle } from '../Components/Common/BrandedTitle';
import { HeroBanner } from '../Components/Common/HeroBanner';
import { RequestForm } from '../Components/Common/RequestForm';
import { DownloadCta, ShareCta } from '../Components/Common/CTAs';
import FocusCard from '../Components/Common/Team/FocusCard';

interface CaseStudyProps {
    pageContext: {
        pageData: WpGql_Page
    }
}

const Wrapper = styled(ContentContainer)`
    h2 {
        margin-left: 0 !important;
        text-align: left;
        font-size: 40px;
        font-weight: 400;
    }

    p {
        margin: 30px 0;
        font-weight: 300;
        font-style: normal;
    }

    .has-normal-font-size {
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
    }

    .has-extra-small-font-size {
        color: ${props => props.theme.colors.greatlyGrey};
    }

    .case-study--overview,
    .case-study__solution,
    .case-study__results {
        display: flex;
        flex-wrap: wrap;
        padding: 64px 0;

        > * {
            width: 100%;
            flex-basis: 100%;

            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex-basis: 50%;
            }
        }

        > :first-child {
            > * {
                text-align: left;
            }
        }
    }

    .case-study--overview > :first-child {
        padding-right: 2vw;
    }

    .case-study__results {
        padding: 0;

        > :first-child,
        > :last-child {
            width: 100%;
            flex-basis: 100%;
        }

        > :first-child {
            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex-basis: 75%;
                padding-right: 2vw;
            }

            > * {
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
            }
        }

        > :last-child {
            border-top: 1px solid ${props => props.theme.colors.casperGrey};
            border-bottom: 1px solid ${props => props.theme.colors.casperGrey};

            @media (min-width: ${props => props.theme.screensizes.medium}) {
                flex-basis: 25%;
            }

            > * {
                flex-basis: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 30px 0;

                p {
                    margin: 0;
                }

                img {
                    margin-right: 0.5rem;
                    max-width: 32px;
                }
            }
        }
    }

    .case-study__solution {
        > :first-child {
            p {
                color: ${ props => props.theme.colors.greatlyGrey };
            }

            p:first-of-type {
                color: ${ props => props.theme.colors.nearlyNavy };
                font-size: 20px;
                line-height: 24px;
                font-weight: 400;
            }
        }
    }
`

const Team = styled.article`
    width: 100%;
    align-self: center;
`

const TeamWrapper = styled(Team)`
    margin: 0 auto;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        max-width: 80%;
    }
`

const Results = styled.article`
    background-color: ${ props => props.theme.colors.almostWhite };
    padding: 64px 0;
`

const Testimonials = styled.section`
    position: relative;
    width: 100vw;
    height: max-content;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('${overlay}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right center;
    }

    article {
        padding: 64px 0;
        margin: auto;
        width: 50%;
        height: max-content;
    }

    .testimonials__title,
    .testimonials__content,
    .testimonials__author {
        text-align: center;
        display: block;
    }
    
    .testimonials__title,
    .testimonials__author {
        text-transform: uppercase;
        font-weight: bold;
        font-family: ${props => props.theme.fonts.dinNarrow };
    }

    .testimonials__content,
    .testimonials__author {
        color: white;
    }

    .testimonials__title {
        font-size: 32px;
        color: ${props => props.theme.colors.meaningfulMarigold};
        margin-bottom: 16px;
        position: relative;
    }

    .testimonials__content {
        font-weight: 300;
        font-style: italic;
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 24px;
    }

    .testimonials__author {
        font-weight: 700;
        font-style: normal;
    }
`

const CaseStudyTemplate: React.FC <CaseStudyProps> = ( props ) => {
    const {  title, content, postCaseStudyCustomFields, heroBannerOptions, teamFocus, share } = props?.pageContext?.pageData

    const checkHeroBannerOptions = () => {
        const options = Object.keys(heroBannerOptions).length

        let emptyOptions = 0;
        for (const key in heroBannerOptions) {
            if (heroBannerOptions[key] === null || heroBannerOptions[key] === "") emptyOptions += 1
        }

        return ( emptyOptions === options ) ? false : true
    }

    return (
        <>
            { checkHeroBannerOptions() && (
                <HeroBanner data={heroBannerOptions}/>
            )}
            <Wrapper>
                <section className="case-study--overview">
                    <article>
                        <BrandedTitle>Project Overview</BrandedTitle>
                        <article dangerouslySetInnerHTML={{__html: content}}></article>
                    </article>
                    <RequestForm
                        formId={ 1 }
                        formTitle='Want to learn more?'
                        formTagline='Complete the form below and a member of our team will be in touch shortly to discuss your requirements.'
                        presetValues={{input_12: title}}
                    />
                </section>
            </Wrapper>
            { postCaseStudyCustomFields?.testimonials?.length && (
                <Testimonials>
                    <Wrapper>
                        <article>
                            <aside className="testimonials__title">Testimonial</aside>
                            <Slider
                                slidesToShow={1}
                                slidesToScroll={1}
                                infinite={false}
                                arrows={ postCaseStudyCustomFields.testimonials.length > 1 }
                                dots={ postCaseStudyCustomFields.testimonials.length > 1 }
                            >
                                {postCaseStudyCustomFields.testimonials.map((content, index) => (
                                    <div key={`testimonials-${index}`}>
                                        {content.title && (
                                            <p className="testimonials__title">{ content.title} </p>
                                        )}
                                        <p className="testimonials__content">{content.body}</p>
                                        {content.author && (
                                            <p className="testimonials__author">{ content.author} </p>
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        </article>
                    </Wrapper>
                </Testimonials>
            )}
            <Wrapper>
                <section className="case-study__solution">
                    <article>
                        <BrandedTitle>Our Solution</BrandedTitle>
                        <article dangerouslySetInnerHTML={{__html: postCaseStudyCustomFields.solution}}></article>
                    </article>

                    { teamFocus?.members?.length && (
                        <FocusCard
                            members={teamFocus.members.map(member => (
                                {
                                    image: member?.image?.sourceUrl || '',
                                    tagline: member?.tagline || '',
                                    name: member?.name || '',
                                }
                            ))}
                        />
                    ) }
                </section>
            </Wrapper>
            <Results>
                <Wrapper>
                    <article>
                        <BrandedTitle>The Results</BrandedTitle>
                        <div className="case-study__results">
                            <aside dangerouslySetInnerHTML={{__html: postCaseStudyCustomFields.outcome}}></aside>
                            <aside>
                                {share?.links?.length && (
                                    share.links.map((link, index) => (
                                        link.type === 'Download' ? (
                                            <DownloadCta title={ link.text } download={ link.link } key={ `cta-${index}`}/>
                                        ) : (
                                            <ShareCta title={ link.text } href={ link.link } key={ `cta-${index}`}/>
                                        )
                                    ))
                                )}
                            </aside>
                        </div>
                    </article>
                </Wrapper>
            </Results>
        </>
    );
};

export default CaseStudyTemplate;